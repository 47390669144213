import React, { useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Button } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Modal } from "react-bootstrap";

function SignIn({ subdomain }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dashboardLinkRef = useRef(null);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [reason, setReason] = useState({});
  const [isImageModal, setIsImageModal] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append("password", data?.password);
      formData.append("email", data?.email);

      const response = await fetch(
        subdomain === "admin"
          ? `${process.env.REACT_APP_BASEURL}/api/login_signup/admin_login/`
          : `${process.env.REACT_APP_BASEURL}/api/login_signup/vendor-login/`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        toast.success(responseData?.msg || "Login successful!");
        localStorage.setItem("token", responseData.token.access);
        localStorage.setItem("role", subdomain);
        localStorage.setItem("userData", JSON.stringify(responseData?.data));
        setTimeout(() => {
          if (response.status === 200 && response.statusText === "OK") {
            // dashboardLinkRef.current.click();
            navigate("/dashboard", { replace: true });
          }
        }, 3000);
      } else {
        const responseData = await response.json();
        // If Vendor Profile is Rejected
        if (
          responseData &&
          responseData?.error &&
          responseData?.error.includes("Reason is :")
        ) {
          setIsImageModal(true);
          setReason(responseData);
          return;
        }

        if (responseData?.msg === "OTP not verified") {
          setTimeout(() => {
            navigate("/vendor-verification", { state: { email: data?.email } });
          }, 2000);
          return;
        }

        // If Vendor Profile is created but Document Upload is Pending
        if ("document_data" in responseData) {
          toast.error(responseData?.error);

          setTimeout(() => {
            navigate("/documents", { state: responseData?.document_data?.id });
          }, 2000);
          return;
        }
        for (const [key, value] of Object.entries(responseData)) {
          if (key !== "data") {
            const truncatedMessage = `${value}`.substring(0, 100);
            toast.error(truncatedMessage);
          }
        }

        console.error("Failed to submit form");
      }
    } catch (error) {
      toast.error("Something went wrong");
      console.error(error, "Error submitting form:");
    }
  };

  return (
    <>
      <div className="col-lg-6 d-flex justify-content-center">
        <div className="w-100 p-3 p-md-5 card border-0 shadow-sm">
          <form
            className="row g-1 p-3 p-md-4 mt-5"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="col-12 text-center mb-0">
              <h1>Sign in</h1>
              <span>Free access to our dashboard.</span>
            </div>
            <div className="col-12">
              <div className="mb-2">
                <label className="form-label">Email address</label>
                <input
                  type="text"
                  id="email"
                  className="form-control form-control-lg"
                  placeholder="Email address"
                  {...register("email", {
                    pattern: {
                      value:
                        /^(?!.*\.{2})(?!\.)[a-zA-Z0-9._%+-]+(?<!\.)@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?<!\.)$/,
                      message: "Email is invalid",
                    },
                    required: "Email address is required",
                  })}
                />
                {errors.email?.type === "required" && (
                  <p role="alert" className="text-danger">
                    {errors.email.message}
                  </p>
                )}
                {errors.email?.type === "pattern" && (
                  <span className="text-danger">Email is invalid</span>
                )}
              </div>
            </div>
            <div className="col-12">
              <div className="mb-2">
                <div className="form-label">
                  <span className="d-flex justify-content-between align-items-center">
                    Password
                  </span>
                </div>
                <div className="position-relative passwordShow">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control form-control-lg lift"
                    placeholder="***************"
                    {...register("password", { required: true })}
                  />
                  {showPassword ? (
                    <FaEye onClick={togglePasswordVisibility} />
                  ) : (
                    <FaEyeSlash onClick={togglePasswordVisibility} />
                  )}
                </div>
                {errors.password && (
                  <span className="text-danger">This field is required</span>
                )}{" "}
              </div>
            </div>
            <div className="col-12">
              <div className="form-check">
                <div className="d-flex justify-content-between">
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  ></label>

                  <span
                    className="text-secondary cursor-pointer"
                    onClick={() => {
                      navigate("/forgot-password", {
                        state: {
                          userType: subdomain === "admin" ? "admin" : "vendor",
                        },
                      });
                    }}
                  >
                    Forgot Password?
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 text-center mt-4 mb-2">
              <Button
                type="submit"
                className="btn btn-lg btn-block btn-light lift text-uppercase w-100 p-3"
              >
                SIGN IN
              </Button>
              <a
                ref={dashboardLinkRef}
                href="/dashboard"
                style={{ display: "none" }}
              >
                Go to Dashboard
              </a>
            </div>

            {subdomain === "admin" ? (
              <></>
            ) : (
              <>
                <div className="col-12 d-block text-center mt-4">
                  <span>
                    By continuing, you agree to e360 Mart{" "}
                    <NavLink
                      to="https://www.e360mart.com/terms-and-conditions.php"
                      className="text-secondary"
                      target="_blank"
                    >
                      Terms and conditions
                    </NavLink>
                    {" and "}
                    <NavLink
                      to="https://www.e360mart.com/privacy-policy.php"
                      className="text-secondary"
                      target="_blank"
                    >
                      Privacy policy
                    </NavLink>
                    .
                  </span>
                </div>{" "}
                <div className="col-12 text-center mt-2">
                  <span>
                    Don't have an account yet?{" "}
                    <NavLink
                      to={process.env.PUBLIC_URL + "/sign-up"}
                      className="text-secondary"
                    >
                      Sign up here
                    </NavLink>
                  </span>
                </div>
              </>
            )}
          </form>
        </div>
      </div>

      <Modal
        show={isImageModal}
        onHide={() => {
          setIsImageModal(false);
        }}
        style={{ display: "block" }}
      >
        <Modal.Body className="modal-body">
          <div className="deadline-form">
            <p
              style={{
                color: "#f00",
                fontSize: "16px",
                fontWeight: "500",
                padding: "20px",
                background: "#fff",
                borderRadius: "10px",
                border: "1px solid #dedede",
                overflow: "hidden",
                overflowX: "hidden",
                overflowY: "auto",
                height: "200px",
              }}
            >
              {reason?.error}
            </p>
          </div>

          <div className="d-flex justify-content-center">
            <button
              onClick={() => {
                setTimeout(() => {
                  navigate("/documents-verification", { state: reason });
                }, 1000);
              }}
              style={{
                background: "#459daf",
                color: "#fff",
                fontSize: "20px",
                fontWeight: "600",
                width: "150px",
                padding: "6px 25px",
              }}
              type="button"
              className="btn btn-secondary"
            >
              OK
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SignIn;
