import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { CiCircleCheck } from "react-icons/ci";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import st from "./ManagePlans.module.scss";
import { PlanData } from "../../components/Data/PlansList/Plans";

function ManagePlans() {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const [current, setCurrent] = useState({});
  const [table_row, setTable_row] = useState([]);

  const [show, setShow] = useState(false);
  const user = JSON.parse(localStorage.getItem("userData"));
  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  useEffect(() => {
    getPlanList();
    getSubscription();
  }, []);

  async function getSubscription() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/subscriptions/?user_id=${user?.id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const data = await response.json();
      setCurrent(data[0]);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }

  async function getPlanList() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/plans/`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const { data } = await response.json();
      setTable_row(data);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    } finally {
      reset();
    }
  }

  const onSubmit = async ({ id, price, price3, price6, price12 }) => {
    try {
      const formData = new FormData();
      formData.append("price", price);
      formData.append("price3", price3);
      formData.append("price6", price6);
      formData.append("price12", price12);

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/plans/${id + "/"}`,
        {
          method: "PATCH",
          body: formData,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        toast.success(responseData.msg);

        if (response.status === 200 && response.statusText === "OK") {
          toast.success("Plan updated successfully");
        }
        getPlanList();
        handleClose();
      } else {
        const data = await response.json();
        toast.error(Object.values(data).join(", ") || "Something went wrong");
        console.error("Failed to submit form");
      }
    } catch (error) {
      toast.error("Something went wrong");
      console.error(error, "Error submitting form:");
    }
  };

  return (
    <>
      <div className="body d-flex py-3 mt-4">
        <div className="choose-plan-outer">
          <div className="choose-plan-heading">
            <h3 className="fw-bold mb-3">Manage Plans</h3>
          </div>
          <div className="row">
            {table_row?.length === 0 && (
              <div className="spinnerCenter">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            )}
            {table_row?.map((plan, index) => (
              <div className="col-md-4" key={index}>
                <div className="card card-plen">
                  <h4>{plan?.name}</h4>
                  {plan?.name !== "Free" && (
                    <h3>
                      {plan?.price ? plan?.price?.toFixed(2) : 0} TZS{" "}
                      <span
                        className="cursor-pointer"
                        onClick={() => {
                          handleShow();
                          reset({
                            id: plan?.id,
                            price: plan?.price,
                            price3: plan?.price3,
                            price6: plan?.price6,
                            price12: plan?.price12,
                          });
                        }}
                      >
                        <i className="icofont-ui-edit"></i>
                      </span>
                    </h3>
                  )}
                  <ul>
                    {PlanData?.filter(
                      (data) => data?.name === plan?.name
                    )?.[0]?.list?.map((list) => (
                      <li>
                        <CiCircleCheck /> {list}
                      </li>
                    ))}
                  </ul>
                  {/* <button>Choose</button> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Manage Price</Modal.Title>
        </Modal.Header>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div>
              <div className="priceValueBox">
                <label>1 Month Price</label>
                <div className="position-relative">
                  <input
                    type="text"
                    placeholder="Price"
                    className="form-control"
                    {...register("price", { required: true })}
                  />
                  <span>TZS</span>
                </div>
              </div>
              {errors.price && (
                <p style={{ color: "red" }}>This field is required</p>
              )}
            </div>
            <div>
              <div className="priceValueBox">
                <label>3 Month Price</label>
                <div className="position-relative">
                  <input
                    type="text"
                    placeholder="Price"
                    className="form-control"
                    {...register("price3", { required: true })}
                  />
                  <span>TZS</span>
                </div>
              </div>
              {errors.price3 && (
                <p style={{ color: "red" }}>This field is required</p>
              )}
            </div>
            <div>
              <div className="priceValueBox">
                <label>6 Month Price</label>
                <div className="position-relative">
                  <input
                    type="text"
                    placeholder="Price"
                    className="form-control"
                    {...register("price6", { required: true })}
                  />
                  <span>TZS</span>
                </div>
              </div>
              {errors.price6 && (
                <p style={{ color: "red" }}>This field is required</p>
              )}
            </div>
            <div>
              <div className="priceValueBox">
                <label>Annual Price</label>
                <div className="position-relative">
                  <input
                    type="text"
                    placeholder="Price"
                    className="form-control"
                    {...register("price12", { required: true })}
                  />
                  <span>TZS</span>
                </div>
              </div>
              {errors.price12 && (
                <p style={{ color: "red" }}>This field is required</p>
              )}
            </div>

            <div className={`${st.modalButtonBottom}`}>
              <button
                onClick={() => {
                  handleClose();
                }}
                type="button"
                className="btn btn-secondary"
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-primary">
                Save
              </button>
            </div>
          </Modal.Body>
        </form>
      </Modal>
    </>
  );
}
export default ManagePlans;
