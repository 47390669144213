import React, { useState, useEffect, Fragment } from "react";
import st from "../../style.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row, Col, Card } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { toast } from "react-toastify";
import { RxCross2 } from "react-icons/rx";
import { HiPlusSm } from "react-icons/hi";
import ImageModel from "../../components/common/ImageModel";

function ProductDetails() {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  let values = {};
  const userData = JSON.parse(localStorage.getItem("userData") ?? {});

  if (params && params?.id) {
    values = {
      id: location?.state?.id,
      title: location?.state?.title,
      description: location?.state?.description,
      category: {
        value: location?.state?.category?.value,
        label: location?.state?.category?.label,
      },
      sub_category: {
        value: location?.state?.sub_category?.value,
        label: location?.state?.sub_category?.label,
      },
      original_price: location?.state?.original_price,
      discount_price: location?.state?.discount_price,
      warranty: location?.state?.warranty,
    };
  }
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    watch,
    trigger,
    control,
    setValue,
  } = useForm({ values });

  const [dynamicForm, setDynamicForm] = useState([]);
  const [subCategory, setSubcategory] = useState([]);
  const [category, setCategory] = useState([]);
  const [deleteImages, setDeleteImages] = useState([]);
  const [isImageModal, setIsImageModal] = useState(false);
  const [imageInPopup, setImageInPopup] = useState("");
  const [imageFiles, setImageFiles] = useState([]);
  const [charCount, setCharCount] = useState(0);
  const originalPrice = watch("original_price");

  const [isSubmit, setIsSubmit] = useState({ draft: false, submit: false });

  const description = watch("description", "");

  // Update character count whenever description changes
  React.useEffect(() => {
    setCharCount(description.length > 800 ? 800 : description.length);
  }, [description]);

  const handleDescriptionChange = (event) => {
    const value = event.target.value;
    if (value.length <= 800) {
      setValue("description", value);
      setCharCount(value.length);
      trigger("description"); // Trigger validation on change
    } else {
      // If the length exceeds 800, truncate the value
      event.target.value = value.substring(0, 800);
    }
  };

  async function getDynamicFormByCatAndSubCat({
    category_id,
    sub_category_id,
    id = null,
    product_form_data,
  }) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/product/dynamic-form/?category_id=${category_id}&sub_category_id=${sub_category_id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        const data = await response.json();
        toast.warn(data.message || "Something went wrong!");
        throw new Error(data.message || response.status);
      }

      // Handle response if necessary
      const dynamicData = await response.json();
      const data = dynamicData.data;
      if (data && data[0]?.sub_category_form_status === true) {
        if (!id) {
          setDynamicForm(data?.[0]?.sub_category_form);
        } else {
          const editForm = data?.[0]?.sub_category_form.map((item) => {
            if (item.fieldHeading in product_form_data) {
              item.fieldValue = product_form_data[item.fieldHeading];
              return item;
            }
            return item;
          });
          setDynamicForm(editForm);
        }
      } else if (
        data &&
        data[0]?.sub_category_form_status === false &&
        data[0]?.category_form_status === true
      ) {
        if (!id) {
          setDynamicForm(data?.[0]?.category_form);
        } else {
          const editForm = data?.[0]?.category_form.map((item) => {
            if (item.fieldHeading in product_form_data) {
              item.fieldValue = product_form_data[item.fieldHeading];
              return item;
            }
            return item;
          });
          setDynamicForm(editForm);
        }
      } else if (
        data &&
        data[0]?.sub_category_form_status === true &&
        data[0]?.category_form_status === true
      ) {
        if (!id) {
          setDynamicForm(data?.[0]?.sub_category_form);
        } else {
          const editForm = data?.[0]?.sub_category_form.map((item) => {
            if (item.fieldHeading in product_form_data) {
              item.fieldValue = product_form_data[item.fieldHeading];
              return item;
            }
            return item;
          });
          setDynamicForm(editForm);
        }
      }

      return data;
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }

  async function getCategory() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/product/category/`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const { data } = await response.json();
      const options = data
        .map((item) => ({
          value: item?.id,
          label: item?.category_name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

      setCategory(options);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }

  async function getSubcategory(id) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/product/sub_category/`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const { data } = await response.json();
      const categorySpecific = data
        .filter((item) => item.category.id === id)
        .map((item) => ({
          value: item?.id,
          label: item?.subcategory_name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
      setValue("sub_category", "");
      setSubcategory(categorySpecific);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }

  async function getSubcategoryDefault(id) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/product/category/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const data = await response.json();

      setSubcategory(
        data.subcategories.map((item) => ({
          value: item?.id,
          label: item?.subcategory_name,
        }))
      );
      reset({
        sub_category: {
          value: location?.state?.sub_category?.value,
          label: location?.state?.sub_category?.label,
        },
      });
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }
  const [images, setImages] = useState([]);

  useEffect(() => {
    getCategory();
    if (params && params.id) {
      getDynamicFormByCatAndSubCat({
        category_id: location?.state?.category?.value,
        sub_category_id: location?.state?.sub_category?.value,
        id: location?.state?.id,
        product_form_data: location?.state?.product_form_data,
      });
      getSubcategoryDefault(location?.state?.category?.value);
    }
  }, []);

  useEffect(() => {
    if (location?.state?.product_images) {
      setImages(location.state?.product_images);
    }
  }, []);

  const onSubmit = async ({
    title,
    category,
    sub_category,
    images,
    original_price,
    discount_price,
    payment_status,
    warranty,
    description,
    id,
    product_form_data,
    ...rest
  }) => {
    setIsSubmit((prev) => {
      if (payment_status === "draft") {
        return { ...prev, draft: true };
      } else {
        return { ...prev, submit: true };
      }
    });
    try {
      let user_id = JSON.parse(localStorage.getItem("userData"));
      const formData = new FormData();
      formData.append("title", title);
      formData.append("category", category.value);
      if (sub_category.value)
        formData.append("sub_category", sub_category.value);
      if (location?.state?.type !== "add") {
        formData.append(
          "deleted_images",
          deleteImages
            .filter((item) => item && item?.id)
            .filter(function (item, pos, self) {
              return self.indexOf(item) === pos;
            })
            .map((item) => item?.id)
            .join(",")
        );
      }
      Array.from(imageFiles).forEach((image) => {
        formData.append("uploaded_images", image);
      });
      formData.append("payment_status", payment_status);
      formData.append(
        "original_price",
        original_price ? original_price : location?.state?.original_price
      );
      formData.append(
        "discount_price",
        discount_price ? discount_price : location?.state?.discount_price
      );
      formData.append(
        "warranty",
        warranty ? warranty : location?.state?.warranty
      );
      formData.append("description", description);
      formData.append("product_form_data", JSON.stringify(rest));
      if (user_id.is_vendor) {
        formData.append("user", user_id?.id);
      } else {
        formData.append("user", location?.state?.vendor?.id);
      }

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/product/product_form/${
          location?.state?.type === "edit" ? location?.state?.id + "/" : ""
        }`,
        {
          method: location?.state?.type === "edit" ? "PATCH" : "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        const data = await response.json();
        if (data?.msg) {
          toast.error(data?.msg);
        }
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      // const data = await response.json();
      if (response.status === 201 && response.statusText === "Created") {
        reset();
        toast.success("Product created successfully");
        setTimeout(() => {
          navigate("/product-list");
        }, 500);
      }
      if (response.status === 200 && response.statusText === "OK") {
        const data = await response.json();
        if (
          data?.msg === "Subscription plan expired please upgrade your plan"
        ) {
          toast.error(data?.msg);
          return;
        }

        reset();
        toast.success("Product updated successfully");
        setTimeout(() => {
          navigate("/product-list");
        }, 500);
      }
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
      // toast.error("Something went Wrong");
    } finally {
      setIsSubmit({ draft: false, submit: false });
    }
  };

  const handleImageChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    if (selectedFiles.length + images.length > 5) {
      toast.error("You can only upload a maximum of 5 images");
      return;
    }
    setImageFiles((prevImageFiles) => [...prevImageFiles, ...selectedFiles]);

    const newImages = selectedFiles.map((file, index) => {
      return { image_url: URL.createObjectURL(file) };
    });

    setImages((prevImages) => [...prevImages, ...newImages]);
  };

  const handleRemoveImage = (index) => {
    setDeleteImages((prevImgObj) => [
      ...prevImgObj,
      ...images.filter((_, i) => i === index),
    ]);
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    setImageFiles((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  function onhide() {
    setIsImageModal(false);
  }

  useEffect(() => {
    if (params && params.id) {
      dynamicForm.forEach((form) => {
        if (form?.fieldType?.value === "dropdown") {
          setTimeout(() => {
            reset({
              [`${form?.fieldHeading}`]: form?.fieldValue,
            });
          }, 1000);
        }
      });
    }
  }, [dynamicForm, reset, params]);
  return (
    <>
      <section className={`marginTopMobile30 ${st.pageWrapper} `}>
        <div className={`${st.pageWrapperInside}`}>
          <Card>
            <Card.Body>
              <div className="deadline-form">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row g-3 mb-3 mt-2">
                    <Col md={12} className={`mt-5`}>
                      <h3 className="fw-bold mb-0">Product Details</h3>
                    </Col>
                    <Col md={6} className={`${st.formBox}`}>
                      <label className={`${st.labelTitle}`}>Category</label>
                      <Controller
                        name="category"
                        control={control}
                        rules={{ required: true }} // Required field validation rule
                        render={({ field }) => (
                          <Select
                            {...field}
                            isSearchable={false}
                            className="react-dropdown"
                            classNamePrefix="dropdown"
                            options={category}
                            onChange={(event) => {
                              field.onChange(event);
                              getSubcategory(event.value);
                            }}
                          />
                        )}
                      />
                      {errors.category && (
                        <span className="text-danger">
                          {errors.category.message || "Category is required"}
                        </span>
                      )}
                    </Col>
                    <Col md={6} className={`${st.formBox}`}>
                      <label className={`${st.labelTitle}`}>
                        {" "}
                        Sub-category
                      </label>
                      <Controller
                        name="sub_category"
                        control={control}
                        rules={{ required: true }} // Required field validation rule
                        render={({ field }) => (
                          <Select
                            {...field}
                            isSearchable={false}
                            className="react-dropdown"
                            classNamePrefix="dropdown"
                            options={subCategory}
                            onChange={(event) => {
                              field.onChange(event);
                              getDynamicFormByCatAndSubCat({
                                sub_category_id: event?.value,
                                category_id: watch("category")?.value,
                                id: location?.state?.id,
                                product_form_data:
                                  location?.state?.product_form_data,
                              });
                            }}
                          />
                        )}
                      />
                      {errors.sub_category && (
                        <span className="text-danger">
                          {errors.sub_category.message ||
                            "Sub category is required"}
                        </span>
                      )}
                    </Col>
                    <Col md={12} className={`${st.formBox}`}>
                      <label className={`${st.labelTitle}`}>
                        {" "}
                        Product Title
                      </label>
                      <input
                        type="text"
                        {...register("title", { required: true })}
                        className="form-control"
                        placeholder="Product-title"
                      />
                      {errors.title && (
                        <span className="text-danger">
                          This field is required
                        </span>
                      )}
                    </Col>
                  </div>
                  <Row>
                    {dynamicForm?.map((form, index) => {
                      if (form?.fieldType?.value === "input") {
                        return (
                          <Col md={6} className={`${st.formBox}`} key={index}>
                            <label className={`${st.labelTitle}`}>
                              {form?.fieldHeading}
                            </label>
                            <input
                              type="text"
                              className={`form-control`}
                              {...register(`${form?.fieldHeading}`, {
                                required: form?.fieldRequired,
                                value: form?.fieldValue,
                              })}
                            />
                            {errors[`${form?.fieldHeading}`] && (
                              <span className="text-danger">
                                This field is required
                              </span>
                            )}
                          </Col>
                        );
                      }
                      if (form?.fieldType?.value === "textarea") {
                        return (
                          <Col md={12} className={`${st.formBox}`} key={index}>
                            <label className={`${st.labelTitle}`}>
                              {" "}
                              {form?.fieldHeading}
                            </label>
                            <textarea
                              rows={4}
                              className={`form-control`}
                              {...register(`${form?.fieldHeading}`, {
                                value: form?.fieldValue,
                                required: form?.fieldRequired,
                              })}
                            />
                            {errors[`${form?.fieldHeading}`] && (
                              <span className="text-danger">
                                This field is required
                              </span>
                            )}
                          </Col>
                        );
                      }
                      if (form?.fieldType?.value === "dropdown") {
                        // reset({ [form?.fieldHeading]: form?.fieldValue });
                        return (
                          <Col md={12} className={`${st.formBox}`} key={index}>
                            <Col md={6} className="pe-3">
                              <label className={`${st.labelTitle}`}>
                                {form?.fieldHeading}
                              </label>
                              <Controller
                                name={`${form?.fieldHeading}`}
                                control={control}
                                rules={{
                                  required: form?.fieldRequired,
                                }}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    isSearchable={false}
                                    // value={form?.fieldValue}
                                    className="react-dropdown"
                                    classNamePrefix="dropdown"
                                    options={form?.nestedOptions}
                                    onChange={(event) => {
                                      field.onChange(event);
                                    }}
                                  />
                                )}
                              />
                              {errors[`${form?.fieldHeading}`] && (
                                <span className="text-danger">
                                  This field is required
                                </span>
                              )}
                            </Col>
                          </Col>
                        );
                      }

                      if (form?.fieldType?.value === "date") {
                        return (
                          <Col md={6} className={`${st.formBox}`} key={index}>
                            <label className={`${st.labelTitle}`}>
                              {form?.fieldHeading}
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              {...register(`${form?.fieldHeading}`, {
                                value: form?.fieldValue,
                                required: form?.fieldRequired,
                              })}
                            />
                            {errors[`${form?.fieldHeading}`] && (
                              <span className="text-danger">
                                this field is required
                              </span>
                            )}
                          </Col>
                        );
                      }

                      if (form?.fieldType?.value === "time") {
                        return (
                          <Col md={6} className={`${st.formBox}`} key={index}>
                            <label className={`${st.labelTitle}`}>
                              {form?.fieldHeading}
                            </label>
                            <input
                              type="time"
                              className="form-control"
                              {...register(`${form?.fieldHeading}`, {
                                value: form?.fieldValue,
                                required: form?.fieldRequired,
                              })}
                            />
                            {errors[`${form?.fieldHeading}`] && (
                              <span className="text-danger">
                                This field is required
                              </span>
                            )}
                          </Col>
                        );
                      }
                      if (form?.fieldType?.value === "radio") {
                        return (
                          <Col md={4} className={`${st.formBox}`} key={index}>
                            <label className={`${st.labelTitle}`}>
                              {form?.fieldHeading}
                            </label>
                            <div className="">
                              {form?.nestedOptions?.map((r) => {
                                return (
                                  <>
                                    <div className="d-flex gap-1 mb-2">
                                      <input
                                        {...register(`${form?.fieldHeading}`, {
                                          required: form?.fieldRequired,
                                        })}
                                        id={`${r.value}`}
                                        type="radio"
                                        value={`${r.value}`}
                                        defaultChecked={
                                          form?.fieldValue === r.value
                                        }
                                      />
                                      <label
                                        className={`form-control ${st.labelTitle} mb-0`}
                                        htmlFor={`${r.value}`}
                                      >
                                        {r.value}
                                      </label>
                                    </div>
                                  </>
                                );
                              })}
                              {errors[`${form?.fieldHeading}`] && (
                                <span className="text-danger">
                                  This field is required
                                </span>
                              )}
                            </div>
                          </Col>
                        );
                      }
                      return <Fragment key={index}></Fragment>;
                    })}
                  </Row>

                  <Row>
                    <Col md={6} className="mt-3">
                      <label className="labelTitle">Original price</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Original price"
                        {...register("original_price", {
                          required: "Original price is required",
                          min: {
                            value: 0,
                            message: "Original price cannot be negative",
                          },
                          validate: {
                            maxLength: (value) =>
                              value.length <= 15 ||
                              "Original price cannot exceed 15 digits",
                          },
                          pattern: {
                            value: /^\d+$/,
                            message: "Only numerical values are allowed",
                          },
                        })}
                      />
                      {errors.original_price && (
                        <span className="text-danger">
                          {errors.original_price.message}
                        </span>
                      )}
                    </Col>
                    <Col md={6} className="mt-3">
                      <label className="labelTitle">Discount price</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Discount price"
                        {...register("discount_price", {
                          required: "Discount price is required",
                          min: {
                            value: 0,
                            message: "Discount price cannot be negative",
                          },
                          validate: {
                            maxLength: (value) =>
                              value.length <= 15 ||
                              "Discount price cannot exceed 15 digits",
                            lessThanOriginal: (value) =>
                              parseFloat(value) <= parseFloat(originalPrice) ||
                              "Discount price should be less than or equal to original price",
                          },
                          pattern: {
                            value: /^\d+$/,
                            message: "Only numerical values are allowed",
                          },
                        })}
                      />
                      {errors.discount_price && (
                        <span className="text-danger">
                          {errors.discount_price.message}
                        </span>
                      )}
                    </Col>
                    <Col md={6} className="mt-3">
                      <label className="labelTitle">Warranty</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Warranty"
                        {...register("warranty", {
                          required: "Warranty is required",
                        })}
                      />
                      {errors.warranty && (
                        <span className="text-danger">
                          {errors.warranty.message}
                        </span>
                      )}
                    </Col>

                    <Col md={12} className={`mt-3 ${st.formBox}`}>
                      <label className={`${st.labelTitle}`}>Description</label>
                      <textarea
                        className="form-control h-auto"
                        rows={4}
                        placeholder="Type here..."
                        {...register("description", {
                          required: "Description is required",
                          maxLength: {
                            value: 800,
                            message: "Description cannot exceed 800 characters",
                          },
                          onChange: (e) => {
                            handleDescriptionChange(e);
                          },
                        })}
                      ></textarea>
                      <small className="form-text text-muted">
                        {charCount}/800 characters
                      </small>
                      {errors.description && (
                        <p className="text-danger">
                          {errors.description.message}
                        </p>
                      )}
                    </Col>
                  </Row>

                  <div className="col-sm-12 mt-3">
                    <label htmlFor="taxtno" className="form-label">
                      Product Images
                    </label>

                    <div className="d-flex gap-3">
                      {images.map((image, index) => (
                        <div
                          key={index}
                          className={`${st.dummyImage} cursor-pointer`}
                        >
                          <img
                            src={image?.image_url}
                            alt={`uploaded ${index}`}
                            onClick={() => {
                              setIsImageModal(true);
                              setImageInPopup(image?.image_url);
                            }}
                          />
                          <div
                            className={`${st.editIcon}`}
                            onClick={() => handleRemoveImage(index)}
                          >
                            <RxCross2 />
                          </div>
                        </div>
                      ))}
                      <div className={`${st.dummyImage}`}>
                        <HiPlusSm
                        // onClick={() =>
                        //   document.getElementById("fileInput").click()
                        // }
                        />
                        <input
                          type="file"
                          id="fileInput"
                          accept="image/*"
                          // style={{ display: "none" }}
                          multiple
                          {...register("images", {
                            required: images.length > 0 ? false : true,
                            onChange: (e) => handleImageChange(e),
                          })}
                        />
                      </div>
                    </div>
                    {errors.images && (
                      <span className="text-danger">
                        Product Image is required
                      </span>
                    )}
                  </div>
                  <div className={`mt-3 ${st.buttonBottom}`}>
                    <button
                      onClick={() => {
                        navigate("/product-list", { replace: true });
                      }}
                      type="button"
                      className="btn btn-secondary"
                    >
                      Back
                    </button>
                    {userData && userData?.is_vendor ? (
                      <button
                        type="submit"
                        onClick={() => {
                          setValue("payment_status", "draft");
                        }}
                        className="btn btn-primary"
                        disabled={isSubmit.draft}
                      >
                        Draft
                      </button>
                    ) : (
                      <></>
                    )}

                    <button
                      type="submit"
                      onClick={() => {
                        setValue("payment_status", "paid");
                      }}
                      className="btn btn-primary"
                      disabled={isSubmit.submit}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </Card.Body>
          </Card>
        </div>
      </section>
      <ImageModel
        isImageModal={isImageModal}
        image={imageInPopup}
        onhide={onhide}
      />
    </>
  );
}
export default ProductDetails;
