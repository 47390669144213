import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import PageHeader1 from "../../components/common/PageHeader1";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { validateEmail } from "../../components/common/helper";
import st from "./common.module.scss";
import { handleKeyDown } from "../../helper/validation";
import { useNavigate } from "react-router";
import { FilterComponent } from "../../components/common/FilterComponent";
import { IoChatboxSharp } from "react-icons/io5";

function BuyerList() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [table_row, setTable_row] = useState([]);
  const [ismodal, setIsmodal] = useState(false);
  const [iseditmodal, setIseditmodal] = useState(false);
  const [isDeletemodal, setIsDeletemodal] = useState(false);
  const [user, setuser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const columns = () => {
    return [
      {
        name: " ID",
        selector: (row) => row.id,
        sortable: true,
        compact: true,
      },
      {
        name: "Full Name",
        selector: (row) => row.full_name,
        cell: (row) => (
          <>
            {/* <img className="avatar rounded lg border" src={row.image} alt="" />{" "} */}
            <span className="px-2">
              {/* <Link to={process.env.PUBLIC_URL + "/buyer-detail"}> */}
              {row.full_name}
              {/* </Link> */}
            </span>
          </>
        ),
        sortable: true,
        compact: true,
        minWidth: "150px",
      },
      {
        name: "Phone No.",
        selector: (row) => row.phone,
        sortable: true,
        compact: true,
      },
      {
        name: "Email",
        selector: (row) => row.email,
        sortable: true,
        compact: true,
        minWidth: "150px",
      },
      {
        name: "ACTION",
        selector: (row) => {},
        sortable: true,
        compact: true,
        cell: (row) => (
          <div
            className="btn-group"
            role="group"
            aria-label="Basic outlined example"
          >
            <button
              type="button"
              onClick={() => {
                navigate(`/chat/${row?.id}`, { state: { buyer: true } });
              }}
              className="btn btn-outline-secondary deleterow"
            >
              <IoChatboxSharp />
            </button>
            <button
              type="button"
              onClick={() => {
                setIsDeletemodal(true);
                setuser(row);
              }}
              className="btn btn-outline-secondary deleterow"
            >
              <i className="icofont-ui-delete text-danger"></i>
            </button>
            <button
              type="button"
              onClick={() => {
                navigate("/buyer-details", { state: row });
              }}
              className="btn btn-outline-secondary deleterow"
            >
              <i className="icofont-eye-alt"></i>
            </button>
          </div>
        ),
      },
    ];
  };
  const onDeleteSubmit = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/login_signup/user-retrieve/${user?.id}/`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      if (response.status === 204 && response.statusText === "No Content") {
        toast.success("Buyer deleted successfully");
      }
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    } finally {
      setIsDeletemodal();
      getBuyerList();
    }
  };

  async function getBuyerList() {
    try {
      // setIsLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/login_signup/user-retrieve/`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
        // setIsLoading(false);
      } else {
        // setIsLoading(false);
      }

      // Handle response if necessary
      const { data } = await response.json();
      setTable_row(data.filter((item) => item?.is_buyer));
      // ...
    } catch (error) {
      // setIsLoading(false);
      // Capture the error message to display to the user
      console.error(error);
    } finally {
      setIsLoading(false);
      reset();
    }
  }

  useEffect(() => {
    getBuyerList();
  }, []);

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append("full_name", data?.firstName + " " + data?.lastName);
      formData.append("first_name", data?.firstName);
      formData.append("last_name", data?.lastName);
      formData.append("email", data?.email);
      formData.append("phone", data?.phone);
      data?.type === "add" && formData.append("is_buyer", true);
      data?.type === "add" && formData.append("is_active", true);

      // formData.append("password", data?.password);
      // formData.append("password2", data?.password2);
      formData.append(
        "address",
        `${data?.apartment_number} ${data?.street_name} ${data?.city} ${data?.state} ${data?.zip_code}`
      );
      formData.append("apartment_number", data?.apartment_number);
      formData.append("street_name", data?.street_name);
      formData.append("city", data?.city);
      formData.append("state", data?.state);
      formData.append("zip_code", data?.zip_code);

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/login_signup/user-retrieve/${
          data?.type === "add" ? "" : data.id + "/"
        }`,
        {
          method: data?.type === "add" ? "POST" : "PATCH",
          body: formData,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        toast.success(responseData.msg);
        if (response.status === 201 && response.statusText === "Created") {
          setTimeout(() => {
            toast.success("Buyer created successfully");
            setIsmodal(false);
          }, 500);
        }
        if (response.status === 200 && response.statusText === "OK") {
          toast.success("User updated successfully");
          setIseditmodal(false);
        }
        getBuyerList();
      } else {
        const data = await response.json();
        toast.error(Object.values(data).join(", ") || "Something went wrong");
        console.error("Failed to submit form");
      }
    } catch (error) {
      toast.error("Something went wrong");
      console.error(error, "Error submitting form:");
    }
  };

  const validatePhone = (value) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(value) || "Phone number must be 10 digits";
  };

  const handlePhoneInput = (e) => {
    if (e.target.value.length > 10) {
      e.target.value = e.target.value.slice(0, 10);
    }
  };

  //Filtered Buyer List
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = table_row.filter((item) => {
    return (
      item?.full_name &&
      item?.full_name.toLowerCase().includes(filterText.toLowerCase())
    );
  });

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <div className="body d-flex">
      <div className="container-xxl">
        <PageHeader1
          pagetitle="Buyer Information"
          modalbutton={() => {
            return (
              <div className="col-auto d-flex w-sm-100">
                {/* <button
                  type="button"
                  onClick={() => {
                    setIsmodal(true);
                    reset();
                    setValue("type", "add");
                  }}
                  className="btn btn-primary btn-set-task w-sm-100"
                >
                  <i className="icofont-plus-circle me-2 fs-6"></i>Add Buyer
                </button> */}
              </div>
            );
          }}
        />
        <div className="row clearfix g-3">
          <div className="col-sm-12 mt-3">
            <div className="card ">
              <div className="card-body">
                <div
                  id="myProjectTable_wrapper"
                  className="dataTables_wrapper dt-bootstrap5 no-footer"
                >
                  <div className="row">
                    <div className="col-sm-12">
                      {isLoading ? (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ minHeight: "70vh" }}
                        >
                          <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <DataTable
                          columns={columns()}
                          defaultSortField="title"
                          pagination
                          selectableRows={false}
                          className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
                          highlightOnHover={true}
                          data={filteredItems}
                          subHeader
                          paginationResetDefaultPage={resetPaginationToggle}
                          subHeaderComponent={subHeaderComponentMemo}
                          persistTableHead
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={iseditmodal}
        onHide={() => {
          setIseditmodal(false);
          reset({});
        }}
        className=""
        style={{ display: "block" }}
      >
        <Modal.Header className="modal-header" closeButton>
          <h5 className="modal-title  fw-bold" id="expeditLabel">
            {" "}
            Edit Buyer
          </h5>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="deadline-form">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row g-3 mb-3">
                <div className="col-sm-12">
                  <label htmlFor="item" className="form-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="item"
                    placeholder="First Name"
                    {...register("firstName", { required: true })}
                    onKeyDown={handleKeyDown} // Add event handler for keydown
                  />
                  {errors.firstName && (
                    <span className="text-danger">First Name is required</span>
                  )}
                </div>
                <div className="col-sm-12">
                  <label htmlFor="item" className="form-label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="item"
                    placeholder="Last Name"
                    {...register("lastName", { required: true })}
                    onKeyDown={handleKeyDown} // Add event handler for keydown
                  />
                  {errors.firstName && (
                    <span className="text-danger">Last Name is required</span>
                  )}
                </div>
                <div className="col-sm-12">
                  <label htmlFor="item" className="form-label">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="item"
                    placeholder="Email"
                    {...register("email", {
                      required: true,
                      validate: validateEmail,
                    })}
                    defaultValue=""
                  />
                  {errors.email && errors.email.type === "required" && (
                    <span className="text-danger">Email is required.</span>
                  )}
                  {errors.email && errors.email.type === "validate" && (
                    <span className="text-danger">
                      Please enter a valid email address.
                    </span>
                  )}

                  {/* {errors.email && <p>{errors.email.message}</p>} */}
                </div>
                <div className="col-sm-12">
                  <label htmlFor="item" className="form-label">
                    Phone
                  </label>
                  <input
                    type="text"
                    placeholder="Phone Number"
                    className="form-control"
                    id="item"
                    {...register("phone", {
                      required: true,
                      validate: validatePhone,
                    })}
                    onInput={handlePhoneInput}
                    defaultValue=""
                  />
                  {errors.phone && errors.phone.type === "required" && (
                    <span className="text-danger">
                      Phone number is required.
                    </span>
                  )}
                  {errors.phone && errors.phone.type === "validate" && (
                    <span className="text-danger">
                      Please enter a valid phone number.
                    </span>
                  )}
                </div>
              </div>
              <div className="row g-3 mb-3">
                <div className="col-sm-6">
                  <label htmlFor="depone" className="form-label">
                    Apartment Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="depone"
                    placeholder=" Apartment Number"
                    {...register("apartment_number", { required: true })}
                  />
                  {errors.apartment_number && (
                    <span className="text-danger">This field is required</span>
                  )}
                </div>
                <div className="col-sm-6">
                  <label htmlFor="depone" className="form-label">
                    Street Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="depone"
                    {...register("street_name", { required: true })}
                  />
                  {errors.street_name && (
                    <span className="text-danger">This field is required</span>
                  )}
                </div>
                <div className="col-sm-6">
                  <label htmlFor="depone" className="form-label">
                    City
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="depone"
                    placeholder="City"
                    {...register("city", { required: true })}
                    onKeyDown={handleKeyDown} // Add event handler for keydown
                  />
                  {errors.city && (
                    <span className="text-danger">This field is required</span>
                  )}
                </div>
                <div className="col-sm-6">
                  <label htmlFor="depone" className="form-label">
                    State
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="depone"
                    placeholder="State"
                    {...register("state", { required: true })}
                    onKeyDown={handleKeyDown} // Add event handler for keydown
                  />
                  {errors.state && (
                    <span className="text-danger">This field is required</span>
                  )}
                </div>
                <div className="col-sm-6">
                  <label htmlFor="depone" className="form-label">
                    Zip Code
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Zip Code"
                    id="depone"
                    {...register("zip_code", { required: true })}
                  />
                  {errors.zip_code && (
                    <span className="text-danger">This field is required</span>
                  )}
                </div>
              </div>
              <div className={`${st.modalButtonBottom}`}>
                <button
                  onClick={() => {
                    setIseditmodal(false);
                  }}
                  type="button"
                  className="btn btn-secondary"
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={ismodal}
        onHide={() => {
          setIsmodal(false);
          reset({});
        }}
        style={{ display: "block" }}
      >
        <Modal.Header className="modal-header" closeButton>
          <h5 className="modal-title  fw-bold" id="expaddLabel">
            Add Buyer
          </h5>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="deadline-form">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row g-3 mb-3">
                <div className="col-sm-12">
                  <label htmlFor="item" className="form-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="item"
                    placeholder="First Name"
                    {...register("firstName", { required: true })}
                    onKeyDown={handleKeyDown} // Add event handler for keydown
                  />
                  {errors.firstName && (
                    <span className="text-danger">First Name is required</span>
                  )}
                </div>
                <div className="col-sm-12">
                  <label htmlFor="item" className="form-label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last Name"
                    id="item"
                    {...register("lastName", { required: true })}
                    onKeyDown={handleKeyDown} // Add event handler for keydown
                  />
                  {errors.firstName && (
                    <span className="text-danger">Last Name is required</span>
                  )}
                </div>
                <div className="col-sm-12">
                  <label htmlFor="item" className="form-label">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="item"
                    placeholder="Email"
                    {...register("email", {
                      required: true,
                      validate: validateEmail,
                    })}
                    defaultValue=""
                  />
                  {errors.email && errors.email.type === "required" && (
                    <span className="text-danger">Email is required.</span>
                  )}
                  {errors.email && errors.email.type === "validate" && (
                    <span className="text-danger">
                      Please enter a valid email address.
                    </span>
                  )}
                </div>
                <div className="col-sm-12">
                  <label htmlFor="item" className="form-label">
                    Phone
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Phone Number"
                    // id="item"
                    {...register("phone", {
                      required: true,
                      validate: validatePhone,
                    })}
                    defaultValue=""
                    // placeholder="Enter your phone number"
                    onInput={handlePhoneInput}
                  />
                  {errors.phone && (
                    <p className="text-danger">{errors.phone.message}</p>
                  )}
                  {errors.phone && errors.phone.type === "required" && (
                    <span className="text-danger">
                      Phone number is required.
                    </span>
                  )}
                  {errors.phone && errors.phone.type === "validate" && (
                    <span className="text-danger">
                      Please enter a valid phone number.
                    </span>
                  )}
                </div>
              </div>
              <div className="row g-3 mb-3">
                <div className="col-sm-6">
                  <label htmlFor="depone" className="form-label">
                    Apartment Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="depone"
                    placeholder="Apartment Number"
                    {...register("apartment_number", { required: true })}
                  />
                  {errors.apartment_number && (
                    <span className="text-danger">This field is required</span>
                  )}
                </div>
                <div className="col-sm-6">
                  <label htmlFor="depone" className="form-label">
                    Street Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="depone"
                    placeholder="Street Name"
                    {...register("street_name", { required: true })}
                  />
                  {errors.street_name && (
                    <span className="text-danger">This field is required</span>
                  )}
                </div>
                <div className="col-sm-6">
                  <label htmlFor="depone" className="form-label">
                    City
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="depone"
                    placeholder="City"
                    {...register("city", { required: true })}
                    onKeyDown={handleKeyDown} // Add event handler for keydown
                  />
                  {errors.city && (
                    <span className="text-danger">This field is required</span>
                  )}
                </div>
                <div className="col-sm-6">
                  <label htmlFor="depone" className="form-label">
                    State
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="depone"
                    placeholder="State"
                    {...register("state", { required: true })}
                    onKeyDown={handleKeyDown} // Add event handler for keydown
                  />
                  {errors.state && (
                    <span className="text-danger">This field is required</span>
                  )}
                </div>
                <div className="col-sm-6">
                  <label htmlFor="depone" className="form-label">
                    Zip Code
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="depone"
                    placeholder="Zip Code"
                    {...register("zip_code", { required: true })}
                  />
                  {errors.zip_code && (
                    <span className="text-danger">This field is required</span>
                  )}
                </div>
              </div>
              <div className={`${st.modalButtonBottom}`}>
                <button
                  onClick={() => {
                    setIsmodal(false);
                    reset();
                  }}
                  type="button"
                  className="btn btn-secondary"
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={isDeletemodal}
        onHide={() => {
          setIsDeletemodal(false);
        }}
        style={{ display: "block" }}
      >
        <Modal.Header className="modal-header" closeButton>
          <h5 className="modal-title  fw-bold" id="expaddLabel">
            Delete Buyer
          </h5>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="deadline-form">
            <form>
              <h4
                style={{
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "600",
                  marginBottom: "20px",
                }}
              >
                Are you sure you want to delete?
              </h4>
              <div className={`${st.modalButtonBottom}`}>
                <button
                  onClick={() => {
                    setIsDeletemodal(false);
                  }}
                  type="button"
                  className="btn btn-secondary"
                >
                  Cancel
                </button>
                <button
                  onClick={onDeleteSubmit}
                  type="button"
                  className="btn btn-primary"
                >
                  Delete
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default BuyerList;
