import React, { useEffect, useState } from "react";
import OculusVR from "../../components/Products/ProductDetail/OculusVR";
import PageHeader1 from "../../components/common/PageHeader1";
import st from "./FAQ.module.scss";
import { StockListData } from "../../components/Data/StockListData";
import DataTable from "react-data-table-component";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { FilterComponent } from "../../components/common/FilterComponent";

function UserReportDetails() {
  const location = useLocation();
  let { user } = location?.state?.product;
  const [queries, setQueries] = useState([]);
  const [isChangeStatusModel, setIsChangeStatusModel] = useState(false);
  const [changeStatusTo, setChangeStatusTo] = useState({});

  const columns = () => {
    return [
      {
        name: "Buyer Name",
        selector: (row) => (row?.buyer ? row?.buyer?.full_name : ""),
        sortable: true,
        compact: true,
      },

      {
        name: "Buyer Email",
        selector: (row) => (row?.buyer ? row?.buyer?.email : ""),
        sortable: true,
        compact: true,
      },

      {
        name: "Queries",
        selector: (row) => row?.reports,
        sortable: true,
        compact: true,
        minWidth: "150px",
        cell: (row) => (
          <>
            <div>
              {/* {row?.report_list.map((item) => {
                if (item === "Other") {
                  return <p key={item?.id}>Other - {row?.description}</p>;
                }
                return <span>{item}</span>;
              })} */}

              <span>
                {row?.report_list
                  ?.filter((item) => item !== "Other")
                  .join(", ")}
              </span>

              {row?.report_list?.filter((item) => item === "Other").length >
                0 && <p>Other - {row?.description}</p>}
            </div>
          </>
        ),
      },
      {
        name: "Status",
        selector: (row) => {},
        sortable: true,
        compact: true,
        minWidth: "150px",
        cell: (row) => (
          <>
            {row.status === "pending" && (
              <button
                type="button"
                className="btn btn-warning text-white"
                onClick={() => {
                  setIsChangeStatusModel(true);
                  setChangeStatusTo({
                    id: row?.id,
                    modelHeading: "Change Product Report Status",
                    status: row.status === "pending" ? "resolved" : "",
                    apiParameters: {
                      status: row.status === "pending" ? "resolved " : "",
                    },
                  });
                }}
              >
                Pending
              </button>
            )}
            {row.status === "resolved" && (
              <button type="button" className="btn btn-success text-white">
                Resolved
              </button>
            )}
          </>
        ),
      },
    ];
  };

  useEffect(() => {
    getProductSpecificReports();
  }, []);

  async function getProductSpecificReports() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/Help_Support/product_reports/?product_id=${location?.state?.product?.id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const data = await response.json();
      setQueries(
        data?.[0]?.reports.filter(
          (report) => report?.status === location?.state?.tab
        ) ?? []
      );
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }

  const onStatusSubmit = async () => {
    try {
      const formData = new FormData();
      Object.entries(changeStatusTo.apiParameters)?.forEach(([key, value]) => {
        formData.append(key, value.trim());
      });

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/Help_Support/report-ASk/${changeStatusTo?.id}/`,
        {
          method: "PATCH",
          body: formData,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      if (response.status === 200 && response.statusText === "OK") {
        toast.success("Buyer report status updated successfully");
      }
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    } finally {
      setIsChangeStatusModel(false);
      getProductSpecificReports();
    }
  };

  //Filtered Queries List
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = queries.filter((item) => {
    return (
      item?.report_list &&
      item?.report_list
        .map((item) => {
          if (item === "Other") {
            return item.description;
          }
          return item;
        })
        .join(",")
        .toLowerCase()
        .includes(filterText.toLowerCase())
    );
  });

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <>
      <div className="container-xxl">
        <PageHeader1 pagetitle="Products Detail" />
        <div className="row g-3 mb-3">
          <OculusVR product={location?.state?.product} />

          <div className="col-md-12">
            <div className={`${st.venderProfile}`}>
              <h3 className="fw-bold mb-0">Vendor</h3>

              <ul className={`${st.productList}`}>
                <li>
                  <span>Full name :</span>
                  <span>{user?.full_name}</span>
                </li>
                <li>
                  <span>Email :</span>
                  <span>{user?.email}</span>
                </li>
                <li>
                  <span>Phone number :</span>
                  <span>{user?.phone}</span>
                </li>
                <li>
                  <span>Shop address :</span>
                  <span>{user?.address}</span>
                </li>
                <li>
                  <span>Shop image</span>
                  <span>
                    <img
                      className="avatar lg rounded-circle img-thumbnail"
                      src={user?.image}
                      alt="shop_image"
                    />
                  </span>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-md-12">
            <div className={`${st.venderProfile}`}>
              <h3 className="fw-bold mb-0">Reports</h3>
              <DataTable
                columns={columns()}
                defaultSortField="title"
                pagination
                selectableRows={false}
                className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
                highlightOnHover={true}
                data={filteredItems}
                subHeader
                paginationResetDefaultPage={resetPaginationToggle}
                subHeaderComponent={subHeaderComponentMemo}
                persistTableHead
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={isChangeStatusModel}
        onHide={() => {
          setIsChangeStatusModel(false);
        }}
        style={{ display: "block" }}
      >
        <Modal.Header className="modal-header" closeButton>
          <h5 className="modal-title  fw-bold" id="expaddLabel">
            {changeStatusTo?.modelHeading}
          </h5>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="deadline-form">
            <form>
              <h4
                style={{
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "600",
                  marginBottom: "20px",
                }}
              >
                Are you sure you want to change the status to{" "}
                {changeStatusTo.status}?
              </h4>
              <div className={`${st.modalButtonBottom}`}>
                <button
                  onClick={() => {
                    setIsChangeStatusModel(false);
                    setChangeStatusTo({});
                  }}
                  type="button"
                  className="btn btn-secondary"
                >
                  Cancel
                </button>
                <button
                  onClick={onStatusSubmit}
                  type="button"
                  className="btn btn-primary"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default UserReportDetails;
