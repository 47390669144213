import React, { useEffect } from "react";
import st from "../../style.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Select from "react-select";
import { Card, Row, Col, Form } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import { FiPlusCircle } from "react-icons/fi";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import NestedRadioOptions from "./NestedRadioOptions";
import NestedDropDownOptions from "./NestedDropDownOptions";
import cx from "./DynamicForm.module.scss";

function AddDynamicForm({ type }) {
  const location = useLocation();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
    control,
  } = useForm();

  const { fields, append, remove, prepend } = useFieldArray({
    control,
    name: "form",
  });

  useEffect(() => {
    if (type === "edit" || type === "view") {
      if (
        location.state?.category_form_status === true &&
        location.state?.sub_category_form_status === true
      ) {
        reset({
          category: {
            value: location.state?.category?.id,
            label: location.state?.category?.category_name,
          },
          subcategory: {
            value: location.state?.subcategory?.id,
            label: location.state?.subcategory?.subcategory_name,
          },
          form: location.state?.sub_category_form.map((item) => {
            if (
              item?.fieldType?.value === "dropdown" ||
              item?.fieldType?.value === "radio"
            ) {
              return {
                ...item,
                nestedOptions: item.nestedOptions.map((subitem) => ({
                  ...subitem,
                  Option: subitem?.value,
                })),
              };
            }
            return item;
          }),
        });
      } else if (
        location.state?.category_form_status === true &&
        location.state?.sub_category_form_status === false
      ) {
        reset({
          category: {
            value: location.state?.category?.id,
            label: location.state?.category?.category_name,
          },
          form: location.state?.category_form.map((item) => {
            if (
              item?.fieldType?.value === "dropdown" ||
              item?.fieldType?.value === "radio"
            ) {
              return {
                ...item,
                nestedOptions: item.nestedOptions.map((subitem) => ({
                  ...subitem,
                  Option: subitem.value,
                })),
              };
            }
            return item;
          }),
        });
      }
    } else if (
      type === "add" &&
      location &&
      location?.state &&
      location?.state?.id
    ) {
      reset({
        category: {
          value: location.state?.category?.id,
          label: location.state?.category?.category_name,
        },
        subcategory: {
          value: location.state?.id,
          label: location.state?.subcategory_name,
        },
      });
      prepend({
        fieldType: "",
        fieldHeading: "",
        fieldValue: "",
        fieldRequired: true,
        nestedOptions: [],
      });
    }
  }, []);

  const options = [
    { value: "input", label: "Short answer" },
    { value: "textarea", label: "Paragraph" },
    { value: "radio", label: "Multiple choice" },
    { value: "dropdown", label: "Drop-down" },
    { value: "date", label: "Date" },
    { value: "time", label: "Time" },
  ];

  const navigate = useNavigate();
  const params = useParams();

  const onSubmit = async ({ subcategory, category, form }) => {
    try {
      const form_field_value = form.map((fv) => ({
        ...fv,
        nestedOptions: fv.nestedOptions.map(({ Option }) => ({
          ...Option,
        })),
      }));

      const formData = {
        category: category?.value,
        subcategory: subcategory?.value,
        category_form: location?.state?.category?.id ? form_field_value : null,
        sub_category_form: location?.state?.id ? form_field_value : null,
      };

      if (type === "add") {
        formData["category"] = location?.state?.category?.id;
        formData["subcategory"] = location?.state?.id;
      }

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/product/dynamic-form/${
          type === "edit" ? params?.id + "/" : ""
        }`,
        {
          method: type === "edit" ? "PATCH" : "POST",
          body: JSON.stringify(formData),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      await response.json();
      if (response.status === 201 && response.statusText === "Created") {
        toast.success("Form created successfully");
      }
      if (response.status === 200 && response.statusText === "OK") {
        toast.success("Form updated successfully");
      }
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    } finally {
      setTimeout(() => {
        navigate("/subcategory-list", { state: location?.state?.category });
        reset();
      }, 1000);
    }
  };
  return (
    <section className={`marginTopMobile ${st.pageWrapper} `}>
      <div className={`${st.pageWrapperInside}`}>
        <Card>
          <Card.Body>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col md={12} className={`mt-4`}>
                  {" "}
                  <nav aria-label="breadcrumb">
                    <ol
                      className="breadcrumb"
                      style={{
                        backgroundColor: "transparent",
                        cursor: "pointer",
                      }}
                    >
                      <li
                        className="breadcrumb-item"
                        onClick={() => {
                          navigate("/category-list");
                        }}
                      >
                        Category
                      </li>
                      <li
                        className="breadcrumb-item"
                        onClick={() => {
                          navigate("/subcategory-list", {
                            state: location?.state?.category,
                          });
                        }}
                      >
                        Sub category
                      </li>

                      <li className="breadcrumb-item">Product Form</li>
                    </ol>
                  </nav>{" "}
                </Col>
                <Col md={12} className={`mt-0`}>
                  <h3 className="fw-bold mb-3">
                    {type === "add" && "Add Product Form"}
                    {type === "edit" && "Edit Product Form"}
                    {type === "view" && "Product Form"}
                  </h3>
                </Col>
                <Col md={11}>
                  <div
                    className={`${st.contentBox}`}
                    style={{
                      background: "transparent",
                      padding: "0px",
                      border: "none",
                    }}
                  >
                    <Row>
                      <Col md={12} lg={12} xl={12}>
                        <Row>
                          <Col lg={4} md={4}>
                            <Form.Group className={`${st.formField}`}>
                              {/* <Controller
                                  name="category"
                                  control={control}
                                  rules={{ required: true }} // Required field validation rule
                                  render={({ field }) => (
                                    <Select
                                      {...field}
                                      isSearchable={false}
                                      className="react-dropdown"
                                      classNamePrefix="dropdown"
                                      options={categoryList.map((item) => ({
                                        value: item?.id,
                                        label: item?.category_name,
                                      }))}
                                      onChange={(event) => {
                                        field.onChange(event);
                                        setSelectedCategory(event);
                                        setSubCategoryOptions(
                                          subCategoryOptions
                                            .filter(
                                              (item) =>
                                                item?.category?.id ===
                                                event?.value
                                            )
                                            .map((item) => ({
                                              value: item?.id,
                                              label: item?.subcategory_name,
                                            }))
                                        );
                                      }}
                                    />
                                  )}
                                /> */}

                              {/* <input type="text" className={`form-control`}/> */}
                              <div className={`${cx.boxDesign}`}>
                                <small>Category</small>
                                <span>
                                  <img
                                    className="avatar rounded-circle"
                                    src={location?.state?.category?.icon_url}
                                    alt="category_image"
                                  />
                                  {location?.state?.category?.category_name}
                                </span>
                              </div>
                            </Form.Group>
                          </Col>
                          <Col lg={4} md={4}>
                            <Form.Group className={`${st.formField}`}>
                              {/* <Form.Label>
                                  Sub Category{" "}
                                  <span className="text-danger"></span>
                                </Form.Label> */}
                              {/* <Controller
                                  name="subcategory"
                                  control={control}
                                  rules={{ required: true }}
                                  render={({ field }) => (
                                    <Select
                                      {...field}
                                      isSearchable={false}
                                      className="react-dropdown"
                                      classNamePrefix="dropdown"
                                      options={subCategoryOptions}
                                      onChange={(event) => {
                                        field.onChange(event);
                                      }}
                                    />
                                  )}
                                /> */}

                              {/* <input type="text" className={`form-control`}/> */}
                              <div className={`${cx.boxDesign}`}>
                                <small>Sub Category</small>
                                <span>
                                  <img
                                    className="avatar rounded-circle"
                                    src={
                                      type === "edit"
                                        ? location?.state?.subcategory?.icon_url
                                        : location?.state?.icon_url
                                    }
                                    alt="sub_category_image"
                                  />
                                  {type === "edit"
                                    ? location?.state?.subcategory
                                        ?.subcategory_name
                                    : location?.state?.subcategory_name}
                                </span>
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  {fields.map((item, index) => (
                    <div className={`position-relative ${st.contentBox}`}>
                      <Row>
                        <Col lg={7} md={7}>
                          <Form.Group
                            className={`${st.formField} ${st.new_formFields}`}
                          >
                            <Form.Control
                              type="text"
                              placeholder="Field heading"
                              {...register(`form.${index}.fieldHeading`, {
                                required: "Field heading is required",
                              })}
                              isInvalid={!!errors.form?.[0]?.fieldHeading}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.form?.[index]?.fieldHeading?.message}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col lg={4} md={4}>
                          <div className={`${st.react_select_drop}`}>
                            <Controller
                              name={`form.${index}.fieldType`}
                              control={control}
                              rules={{
                                required: "This field is required",
                              }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  isSearchable={false}
                                  className={`react-dropdown ${
                                    errors.form?.[index]?.fieldType
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  classNamePrefix="dropdown"
                                  options={options}
                                  onChange={(event) => {
                                    field.onChange(event);
                                  }}
                                />
                              )}
                            />
                            {errors.form?.[index]?.fieldType && (
                              <div className="invalid-feedback d-block">
                                {errors.form?.[index]?.fieldType?.message}
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>

                      {/* {watch(`form.${index}.fieldType`)?.value ===
                          "input" && (
                          <Row>
                            <Col lg={4}>
                              <Form.Group
                                className={`${st.formField} ${st.new_formFields}`}
                              >
                                <Form.Control
                                  type="text"
                                  placeholder="Input Field"
                                  disabled
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        )} */}

                      {/* {watch(`form.${index}.fieldType`)?.value ===
                          "textarea" && (
                          <Row>
                            <Col lg={4}>
                              <Form.Group
                                className={`${st.formField} ${st.new_formFields}`}
                              >
                                <Form.Control
                                  type="text"
                                  placeholder="TextArea"
                                  disabled
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        )} */}

                      {watch(`form.${index}.fieldType`)?.value === "radio" && (
                        <Row>
                          <Col lg={4}>
                            <NestedRadioOptions
                              nestIndex={index}
                              {...{ control, register }}
                            />
                          </Col>
                        </Row>
                      )}

                      {watch(`form.${index}.fieldType`)?.value ===
                        "dropdown" && (
                        <Row>
                          <Col lg={4}>
                            <NestedDropDownOptions
                              nestIndex={index}
                              {...{ control, register }}
                            />
                            {/* <div> 
                            <Form.Group
                              className={`${st.formField} ${st.new_formFields} d-flex`}
                            >
                              <div className="btn">1</div>
                              <Form.Control
                                type="text"
                                placeholder="Option"
                                value="Option 1"
                              />
                            </Form.Group>
                          </div>

                          <div>
                            <Form.Group
                              className={`${st.formField} ${st.new_formFields} d-flex`}
                            >
                              <div className="btn">2</div>
                              <Form.Control
                                type="text"
                                placeholder="Add option"
                              />
                              <button className="btn">
                                <IoClose />
                              </button>
                            </Form.Group>
                          </div> */}
                          </Col>
                        </Row>
                      )}

                      {/* {watch(`form.${index}.fieldType`)?.value === "date" && (
                          <Row>
                            <Col lg={4}>
                              <div>
                                <Form.Group
                                  className={`${st.formField} ${st.new_formFields}`}
                                >
                                  <Form.Control type="date" disabled />
                                </Form.Group>
                              </div>
                            </Col>
                          </Row>
                        )} */}

                      {/* {watch(`form.${index}.fieldType`)?.value === "time" && (
                          <Row>
                            <Col lg={4}>
                              <div>
                                <Form.Group
                                  className={`${st.formField} ${st.new_formFields}`}
                                >
                                  <Form.Control type="time" disabled />
                                </Form.Group>
                              </div>
                            </Col>
                          </Row>
                        )} */}

                      <hr />
                      <Row>
                        <Col lg={12}>
                          <div
                            className={`${st.box_bottom_btnDiv} ${cx.deleteButton}`}
                            onClick={() => remove(index)}
                          >
                            <div className={`${st.delete_box}`}>
                              <button type="button" className={`btn`}>
                                <MdDelete />
                              </button>
                            </div>
                            {/* <div className={`${st.line_set}`}></div> */}
                            {/* <div className={`${st.togle_flex_box}`}>
                            <span>Required</span>
                            <div className={`${st.toggle_btns}`}>
                              <input type="checkbox" id="toggle-btn1" />
                              <label htmlFor="toggle-btn1"></label>
                            </div>
                          </div> */}
                            {/* <div
                          className={`${st.dropDown_boxs} ${st.hover_Box}`}
                        >
                          <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic">
                              <BsThreeDotsVertical />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <div className="ps-2 text-secondary">
                                <small>Show</small>
                              </div>
                              <ul>
                                <li>
                                  <NavLink to={"#"}>
                                    <FaCheck /> Description
                                  </NavLink>
                                </li>
                              </ul>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div> */}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ))}
                  {type === "view" ? (
                    <></>
                  ) : (
                    <button
                      className={`btn text-white ${st.btnDisabled}`}
                      type="submit"
                    >
                      Submit
                    </button>
                  )}
                </Col>
                <Col md={1}>
                  <div
                    className={`${st.sideBtna_showHide}`}
                    onClick={() =>
                      append({
                        fieldType: "",
                        fieldHeading: "",
                        fieldValue: "",
                        fieldRequired: true,
                        nestedOptions: [],
                      })
                    }
                  >
                    <span>
                      <button type="button" className="btn">
                        <FiPlusCircle />
                        Add more
                      </button>
                    </span>
                  </div>
                </Col>
              </Row>
            </form>
          </Card.Body>
        </Card>
      </div>
    </section>
  );
}
export default AddDynamicForm;
