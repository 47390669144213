import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Modal, Spinner } from "react-bootstrap";
import { CiCircleCheck } from "react-icons/ci";
import { toast } from "react-toastify";
import { PlanData } from "../../components/Data/PlansList/Plans";

function Subscription() {
  const [choosePlanPopup, setChoosePlanPopup] = useState(null);
  const [chooseMonth, setChooseMonth] = useState(null);
  const [current, setCurrent] = useState({});
  const [selectedPlan, setSelectedPlan] = useState({});
  const [show, setShow] = useState(false);
  const user = JSON.parse(localStorage.getItem("userData"));
  const [table_row, setTable_row] = useState([]);

  const handleClose = () => {
    setShow(false);
    setChooseMonth(null);
    setChoosePlanPopup(null);
  };
  const handleShow = (plan_type) => {
    setShow(true);
    setChoosePlanPopup(plan_type);
  };

  useEffect(() => {
    getPlanList();
    getSubscription();
  }, []);

  async function getSubscription() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/subscriptions/?user_id=${user?.id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const data = await response.json();
      setCurrent(data[0]);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }

  async function getPlanList() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/plans/`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const { data } = await response.json();
      setTable_row(data);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }

  const selectSubscription = async (plan = 1) => {
    try {
      const formData = new FormData();
      choosePlanPopup !== 1 &&
        chooseMonth &&
        formData.append("duration", chooseMonth);
      formData.append("user", user?.id);
      if (choosePlanPopup) {
        formData.append("plan", choosePlanPopup);
      } else {
        formData.append("plan", plan);
      }

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/subscriptions/${current?.id}/`,
        {
          method: "PATCH",
          body: formData,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        toast.success(responseData.msg);
        if (response.status === 200 && response.statusText === "OK") {
          if (responseData?.plan === 3) {
            user["plan_name"] = "Gold";
          } else if (responseData?.plan === 2) {
            user["plan_name"] = "Silver";
          } else {
            user["plan_name"] = "Free";
          }
          localStorage.setItem("userData", JSON.stringify(user));
          setTimeout(() => {
            toast.success("Subscription updated successfully");

            getPlanList();
            getSubscription();
            handleClose();
          }, 500);
        }
        // getHelpSupportList(); // You may replace this with the appropriate function to update your UI
      } else {
        const data = await response.json();
        toast.error(Object.values(data).join(", ") || "Something went wrong");
        console.error("Failed to submit form");
      }
    } catch (error) {
      toast.error("Something went wrong");
      console.error(error, "Error submitting form:");
    } finally {
      handleClose();
    }
  };

  return (
    <>
      <div className="body d-flex py-3 mt-4">
        <div className="choose-plan-outer">
          <div className="choose-plan-heading">
            <h3 className="fw-bold mb-3">Choose your plan</h3>
            <p>Choose advertising plan to increase visibility.</p>
          </div>
          <div className="row">
            {table_row?.length === 0 && (
              <div className="spinnerCenter">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            )}

            {table_row?.map((plan, index) => (
              <div className="col-md-4" key={index}>
                <div
                  className={`card card-plen ${
                    plan.name === current?.plan?.name
                      ? "bg-primary text-white"
                      : ""
                  }`}
                >
                  <h4>{plan?.name}</h4>
                  {plan?.name !== "Free" && (
                    <h3>
                      {plan?.[
                        `price${
                          current?.duration === 1 ? "" : current?.duration
                        }`
                      ]
                        ? plan?.[
                            `price${
                              current?.duration === 1 ? "" : current?.duration
                            }`
                          ]?.toFixed(2)
                        : 0}{" "}
                      TZS{" "}
                      {plan.name === current?.plan?.name && (
                        <small>
                          / {current?.duration} Month
                          {`${current?.duration === 1 ? "" : "s"}`}
                        </small>
                      )}
                    </h3>
                  )}
                  <ul>
                    {PlanData?.filter(
                      (data) => data?.name === plan?.name
                    )?.[0]?.list?.map((list) => (
                      <li>
                        <CiCircleCheck /> {list}
                      </li>
                    ))}
                  </ul>
                  <button
                    onClick={() => {
                      if (plan?.name === "Free") {
                        if (current?.plan?.name === plan?.name) {
                          return;
                        }
                        selectSubscription(plan?.id);
                      } else {
                        handleShow(plan?.id);
                        setSelectedPlan(plan);
                        setChooseMonth(current?.duration);
                      }
                    }}
                  >
                    Choose
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Choose Visiblity ({selectedPlan?.name})</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <ButtonGroup aria-label="Subscription period" className="hourseWork">
            <Button
              variant={chooseMonth === 1 ? "primary" : "secondary"}
              onClick={() => setChooseMonth(1)}
            >
              1 month ({selectedPlan?.price} TZS)
            </Button>
            <Button
              variant={chooseMonth === 3 ? "primary" : "secondary"}
              onClick={() => setChooseMonth(3)}
            >
              3 months ({selectedPlan?.price3} TZS)
            </Button>
            <Button
              variant={chooseMonth === 6 ? "primary" : "secondary"}
              onClick={() => setChooseMonth(6)}
            >
              6 months ({selectedPlan?.price6} TZS)
            </Button>
            <Button
              variant={chooseMonth === 12 ? "primary" : "secondary"}
              onClick={() => setChooseMonth(12)}
            >
              12 months ({selectedPlan?.price12} TZS)
            </Button>
          </ButtonGroup>
        </Modal.Body>
        <Modal.Footer className="modalButtonBottom1">
          <Button variant="primary" onClick={selectSubscription}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default Subscription;
